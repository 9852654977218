import schttp from 'public/src/services/schttp'
import qs from 'qs'

export const postAddToCart = async (data) => {
  return schttp({
    url: '/order/add_to_cart',
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data, { arrayFormat: 'brackets' }),
    useBffApi: true,
  })
}


export const handleMsgTip = (data, config) => {
  const { code, tips, msg } = data || {}
  const { language } = config || {}
  let type = 'fail'
  let msgContent = tips || msg || language?.SHEIN_KEY_PC_15163 || 'error'
  let isOverLimit = false
  let isDiscountLimitOne = false
  let isDiscountLimit = false
  let isGoodsEmpty = false

  const ERROR_CODE = Number(code)

  switch (ERROR_CODE) {
    case 0:
      type = 'success'
      msgContent = language?.SHEIN_KEY_PC_32150 || ''
      break
    case 500305: // 打折促销 订单限制
      isDiscountLimit = true
      break
    case 300402:
      // 售罄
      msgContent = language?.SHEIN_KEY_PC_15071 || ''
      break
    case 200401:
    case 500301:
      // 超出限购数量
      msgContent = language?.SHEIN_KEY_PC_14661 || ''
      break
    // 单个/全部 闪购商品加车达到上限 中间层处理 tips
    case 500306:
    case 500302:
    case 300417:
      isOverLimit = true
      break
    case 500304: //打折促销 单品限制
      isDiscountLimitOne = true
      msgContent = language.SHEIN_KEY_PC_17492
      break

    case 200301:
      isGoodsEmpty = true
      break

  }

  return {
    type,
    msgContent: msgContent,
    isOverLimit,
    isDiscountLimitOne,
    isDiscountLimit,
    isGoodsEmpty,
  }
}
